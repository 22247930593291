import { PageHeaderWrapper, TextSkeleton } from "@clipboard-health/ui-components";
import { Text, Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { type Shift, shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { parseISO } from "date-fns";

import { IconButton } from "../../../components/IconButton";
import { getGradientByTimeSlot } from "../../../Shift/getGradientByTimeSlot";
import { formatDayName } from "../../../utils/formatDayName";
import { formatDayOfMonth } from "../../../utils/formatDayOfMonth";

interface OnCallShiftDetailsPageHeaderProps {
  shift: Shift | undefined;
  onRefresh: () => Promise<void>;
  onBackClick: () => void;
}

export function OnCallShiftDetailsPageHeader(props: OnCallShiftDetailsPageHeaderProps) {
  const { shift, onRefresh, onBackClick } = props;

  const shiftStartDate = isDefined(shift) ? parseISO(shift.start) : new Date();
  const formattedStartDayName = isDefined(shift)
    ? formatDayName(shiftStartDate, "EEEE", shift?.facility.tmz)
    : "";
  const formattedStartDayOfMonth = isDefined(shift)
    ? formatDayOfMonth(shiftStartDate, shift?.facility.tmz)
    : "";

  return (
    <PageHeaderWrapper
      sx={(theme) => ({
        paddingX: 5,
        paddingBottom: theme.spacing(8),
        borderRadius: 0,
        background: isDefined(shift)
          ? getGradientByTimeSlot({
              timeSlot: shiftPeriodToTimeSlot[shift.name],
              theme,
            })
          : undefined,
      })}
    >
      <IconButton
        iconType="arrow-left"
        variant="outlined"
        onClick={() => {
          onBackClick();
        }}
      />

      <PullToRefresh onRefresh={onRefresh} />

      <Stack
        spacing={4}
        sx={{
          marginTop: 6,
        }}
      >
        {isDefined(shift) ? (
          <Text variant="h5">
            {isDefined(shift?.agentReq) ? <>{shift.agentReq} &nbsp;·&nbsp; On-call</> : "On-call"}
          </Text>
        ) : (
          <TextSkeleton variant="h5" width="30%" />
        )}

        {isDefined(shift) ? (
          <Title component="h1" variant="h3">
            {formattedStartDayName}, {formattedStartDayOfMonth}
          </Title>
        ) : (
          <TextSkeleton variant="h3" width="60%" />
        )}
      </Stack>
    </PageHeaderWrapper>
  );
}
