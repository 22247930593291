import { PageFooter, TextSkeleton } from "@clipboard-health/ui-components";
import { Text, useModalState } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack, ThemeProvider } from "@mui/material";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { Button } from "@src/appV2/redesign/components/Button";
import { PageWrapper } from "@src/appV2/redesign/components/PageWrapper";
import { InstantPayIndicator } from "@src/appV2/redesign/Shift/BottomSheet/OnCallShift/InstantPayIndicator";
import { useGetShift } from "@src/appV2/Shifts/Shift/api/useGetShift";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory, useParams } from "react-router-dom";

import { ShiftUnavailablePage } from "../components/ShiftUnavailablePage";
import { CancelOnCallShiftBottomSheet } from "./components/CancelOnCallShiftBottomSheet";
import { OnCallShiftDetailsPageContent } from "./components/OnCallShiftDetailsPageContent";
import { OnCallShiftDetailsPageHeader } from "./components/OnCallShiftDetailsPageHeader";

export function OnCallShiftDetailsPage() {
  const cancelOnCallShiftModalState = useModalState();
  const history = useHistory();
  const worker = useDefinedWorker();
  const { shiftId } = useParams<{ shiftId: string }>();

  const {
    data: shiftData,
    error: shiftError,
    isSuccess: isShiftSuccess,
    refetch: refetchShift,
  } = useGetShift(shiftId ?? "", {
    enabled: isDefined(shiftId) && shiftId.length > 0,
  });

  const shift = shiftData?.response;

  if (shiftError || !isShiftSuccess) {
    return <ShiftUnavailablePage shift={shift} workerId={worker.userId} error={shiftError} />;
  }

  function handleBackClick() {
    history.goBack();
  }

  // FIXME: We are hard-coding the instant pay status for now.
  const isInstantPay = false;

  // FIXME: We are hard-coding the shift work duration for now.
  const shiftWorkDurationInHours = 7.5;

  let hourlyPay = "";
  let totalPay = "";
  if (isDefined(shift)) {
    const pay = shift.pay ?? 0;
    hourlyPay = formatDollarsAsUsd(pay);
    totalPay = formatDollarsAsUsd(shiftWorkDurationInHours * pay);
  }

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper sx={(theme) => ({ backgroundColor: theme.palette.common.white })}>
        <OnCallShiftDetailsPageHeader
          shift={shift}
          onRefresh={async () => {
            await refetchShift();
          }}
          onBackClick={() => {
            handleBackClick();
          }}
        />

        <OnCallShiftDetailsPageContent shift={shift} />

        <PageFooter>
          <Stack sx={(theme) => ({ width: "100%", gap: "20px", marginBottom: theme.spacing(6) })}>
            <Stack
              direction="row"
              alignItems="baseline"
              justifyContent="space-between"
              sx={(theme) => ({ paddingX: theme.spacing(4) })}
            >
              <Stack direction="row" alignItems="baseline" spacing={1}>
                {isDefined(shift) ? (
                  <>
                    <Text bold variant="h4">
                      {hourlyPay}
                    </Text>
                    <Text variant="body1"> /hr</Text>
                  </>
                ) : (
                  <TextSkeleton variant="h4" width="30%" />
                )}
              </Stack>
              <Stack direction="row" spacing={3} alignItems="center">
                {isDefined(shift) ? (
                  <>
                    <Text>~{totalPay}</Text>
                    {isInstantPay && <InstantPayIndicator />}
                  </>
                ) : (
                  <TextSkeleton variant="body1" width="30%" />
                )}
              </Stack>
            </Stack>

            <Button
              fullWidth
              sx={{ background: "transparent", display: "block" }}
              variant="destructive"
              size="large"
              disabled={!isDefined(shift)}
              onClick={() => {
                cancelOnCallShiftModalState.openModal();
              }}
            >
              Cancel
            </Button>
          </Stack>
        </PageFooter>
      </PageWrapper>

      {isDefined(shift) && (
        <CancelOnCallShiftBottomSheet modalState={cancelOnCallShiftModalState} shiftId={shiftId} />
      )}
    </ThemeProvider>
  );
}
