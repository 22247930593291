import {
  BottomSheet,
  BottomSheetIllustrationContent,
  Divider,
} from "@clipboard-health/ui-components";
import { type UseModalState } from "@clipboard-health/ui-react";

interface OnCallShiftCancelledConfirmationBottomSheetProps {
  modalState: UseModalState;
}

export function OnCallShiftCancelledConfirmationBottomSheet(
  props: OnCallShiftCancelledConfirmationBottomSheetProps
) {
  const { modalState } = props;

  return (
    <BottomSheet modalState={modalState}>
      <BottomSheetIllustrationContent
        illustrationType="success"
        title="Your on-call booking is cancelled"
      />

      <Divider sx={{ marginTop: 10 }}>&nbsp;</Divider>
    </BottomSheet>
  );
}
