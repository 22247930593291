import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { OnCallContentInfoList } from "@src/appV2/redesign/Shift/BottomSheet/OnCallShift/ContentInfoList";
import { OnCallLearnMore } from "@src/appV2/redesign/Shift/BottomSheet/OnCallShift/LearnMore";
import { type Shift, ShiftPeriod, shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { parseISO } from "date-fns";

import { PageContent } from "../../components/PageContent";

interface OnCallShiftDetailsPageProps {
  shift: Shift | undefined;
}

export function OnCallShiftDetailsPageContent(props: OnCallShiftDetailsPageProps) {
  const { shift } = props;

  const timeSlot = isDefined(shift) ? shiftPeriodToTimeSlot[shift.name] : undefined;

  return (
    <PageContent>
      <Stack
        spacing={2}
        sx={{
          marginTop: 8,
        }}
      >
        <OnCallLearnMore
          workplaceId={shift?.facility.userId ?? ""}
          shiftPeriod={shift?.name ?? ShiftPeriod.PM}
        />

        <OnCallContentInfoList
          hourlyPay={shift?.pay}
          startDate={parseISO(shift?.start ?? "")}
          endDate={parseISO(shift?.end ?? "")}
          timeSlot={timeSlot}
          isLoading={!isDefined(shift)}
          qualificationName={shift?.agentReq}
          workplaceTimezone={shift?.facility.tmz}
          workplaceId={shift?.facility.userId ?? ""}
        />
      </Stack>
    </PageContent>
  );
}
