import { BottomSheet, BottomSheetIllustrationContent } from "@clipboard-health/ui-components";
import { Text, type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { logError, logEvent } from "@src/appV2/lib/analytics";
import { IconButton } from "@src/appV2/redesign/components/IconButton";
import { LoadingButton } from "@src/appV2/redesign/components/LoadingButton";
import { useHistory } from "react-router-dom";

import { DialogFooter } from "../../../components/DialogFooter";
import { useEndOnCallShift } from "../../OnCallOffers/useEndOnCallShift";
import { OnCallShiftCancelledConfirmationBottomSheet } from "./OnCallShiftCancelledConfirmationBottomSheet";

interface CancelOnCallShiftBottomSheetProps {
  modalState: UseModalState;
  shiftId: string;
}

export function CancelOnCallShiftBottomSheet(props: CancelOnCallShiftBottomSheetProps) {
  const { modalState, shiftId } = props;

  const onCallShiftCancelledConfirmationModalState = useModalState();

  const { showErrorToast } = useToast();
  const { mutateAsync: endOnCall, isLoading: isLoadingCancelShift } = useEndOnCallShift(shiftId, {
    onError: () => {
      showErrorToast("Error ending on-call");
    },
  });

  const history = useHistory();

  async function handleCancelShift() {
    try {
      const locationAtCancellation = { ...window.location };

      await endOnCall();

      modalState.closeModal();
      onCallShiftCancelledConfirmationModalState.openModal();

      setTimeout(() => {
        const locationAfterDelay = { ...window.location };
        // FIXME: We are relying on comparing the window location and even
        // using it to navigate back to the bookings page. Ideally, we'd be
        // using some routing constants to determine where we should navigate.
        // However, due to the ongoing redesign which involves some changes to
        // routing and paths, we are using the window location for now.
        //
        // If the user hasn't navigated away, navigate back to the bookings page.
        if (locationAfterDelay.pathname === locationAtCancellation.pathname) {
          // The pathname is going to be something like `/home/myShifts/:shiftId`.
          // So grab everything before the /:shiftId.
          const basePath = locationAtCancellation.pathname.split(`/${shiftId}`)[0];
          history.replace(basePath);
        }
      }, 3000);

      logEvent("Shift Cancelled", {
        shiftId,
      });
    } catch (error) {
      showErrorToast("Something went wrong while cancelling your shift. Please try again.");
      logError("Error cancelling shift", {
        error,
        metadata: {
          shiftId,
        },
      });
    }
  }

  return (
    <>
      <BottomSheet modalState={modalState}>
        <Stack
          direction="row"
          justifyContent="end"
          sx={(theme) => ({ marginX: theme.spacing(6), marginTop: theme.spacing(6) })}
        >
          <IconButton
            iconType="close"
            variant="outlined"
            onClick={() => {
              modalState.closeModal();
            }}
          />
        </Stack>

        <BottomSheetIllustrationContent
          illustrationType="cancel-on-call"
          title="Lose on-call access?"
          description={
            <Text>
              Cancelling an on-call shift will remove your ability to work on-call shifts. Are you
              sure you want to cancel?
            </Text>
          }
        />

        <DialogFooter
          orientation="vertical"
          sx={(theme) => ({
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(6),
          })}
        >
          <LoadingButton
            fullWidth
            variant="contained"
            size="large"
            isLoading={isLoadingCancelShift}
            onClick={async () => {
              await handleCancelShift();
            }}
          >
            Confirm cancellation
          </LoadingButton>
        </DialogFooter>
      </BottomSheet>

      <OnCallShiftCancelledConfirmationBottomSheet
        modalState={onCallShiftCancelledConfirmationModalState}
      />
    </>
  );
}
